import React from 'react';
import Button from '@/components/shared/Button/Button';

import './GetInTouch.scss';

const GetInTouch = () => {
  return (
    <div className="get-in-touch__wrapper">
      <div className="get-in-touch__title">We’re here to answer your questions.</div>
      <Button className="get-in-touch__button" tag="link" href="/#contact-us">
        Get in touch
      </Button>
    </div>
  );
};

export default GetInTouch;
